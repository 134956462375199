import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import WOW from "wowjs";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
const Vardhman = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
   <Helmet>
<title>Vardhman | Reversethought</title>
<meta name="description" content="As a mobile app and website development agency in Mumbai, we provide top services for CMS development, UI/ UX Design, and much more. Contact us!" />
        <meta name="robots" content="max-image-preview:large" />
        <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
        <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
        <meta name="keywords" content="ecommerce website design india,best website development company in india,top website development companies in india,web development company,web development company in india,website development in india,best web design company in mumbai,best web design firms,best website designers in india,top web designing companies in mumbai,top website designers in mumbai,web design agency in mumbai,web design company,web design company india,web design company mumbai,web designing company in mumbai,web development services mumbai,website development agency in india,website development service mumbai" />
<link rel="canonical" href="https://www.reversethought.com/vardhman" />
        <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
        <meta property="og:type" content="article" />
<meta property="og:title" content=">Vardhman | Reversethought" />
<meta property="og:description" content="We create immersive, intuitive and inspiring websites for desktops and mobiles. Some of our services include interactive E-commerce websites, CMS Development and UI/UX Design." />
<meta property="og:url" content="https://www.reversethought.com/vardhman" />
        <meta property="article:tag" content="website development in india" />
        <meta property="article:tag" content="best web design company in mumbai" />
        <meta property="article:tag" content="best web design firms" />
        <meta property="article:tag" content="best website designers in india" />
        <meta property="article:tag" content="top web designing companies in mumbai" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rthoughtindia" />
<meta name="twitter:title" content=">Vardhman | Reversethought" />
<meta name="twitter:description" content="We create immersive, intuitive and inspiring websites for desktops and mobiles. Some of our services include interactive E-commerce websites, CMS Development and UI/UX Design." />
        <meta name="google" content="nositelinkssearchbox" />
      </Helmet>

      <section className="ourWork">
        <div className="content_container">
          <h1 className="projectHeading colorBlack fw-800 text-center wow animate__animated animate__fadeIn">
            Vardhman
          </h1>
          <img
            src="/images/works/vardhman/mainv.jpg"
            alt="elleys-electrics"
            className="img-fluid wow animate__animated animate__fadeIn"
          />
          <div className="gridSystem">
            <div className="gridSystemInner">
              <h2 className="colorRed fw-800 wow animate__animated animate__fadeIn">
                Brief
              </h2>
              <p className="colorGrey fw-400 wow animate__animated animate__fadeInLeft">
              Vardhman Group is committed to excellence that the effect of their efforts is visible in everything they do. Their website was a culmination of beautiful design and their company story laid down in an interactive manner which gave users a complete glimpse into their culture, products, manufacturing & design capabilities and more. 
              </p>
              <div className="row m-0 briefDetails wow animate__animated animate__fadeIn">
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Client</h4>
                  <span className="colorGrey fw-400">Vardhman</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Industry</h4>
                  <span className="colorGrey fw-400">Textile</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInRight">
                  <h4 className="colorBlack fw-800">Work</h4>
                  <span className="colorGrey fw-400">
                    Web design and Development
                  </span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInRight">
                  <h4 className="colorBlack fw-800">Technology</h4>
                  <span className="colorGrey fw-400">
                    HTML5, PHP, CSS3, JS, MySQL
                  </span>
                </div>
              </div>
              <Link
                to="https://www.vardhman.com/"
                className="unsetAll"
                target="_blank"
                onMouseEnter={props.onMouseEnterSmall}
                onMouseLeave={props.onMouseLeaveSmall}
              >
                <span className="commonViewAll">Check website</span>
                <Icon icon="clarity:arrow-line" />
              </Link>
            </div>
            <div className="gridSystemInner">
              <div className="d-flex customGap">
                <img
                  src="/images/works/vardhman/m1.jpg"
                  alt="vardhman UI"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight"
                />
                <img
                  src="/images/works/vardhman/m2.jpg"
                  alt="Vardhan UI"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight animate__delay-0.9s"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="serviceImageSection"
        onMouseEnter={props.mouseEnterOnBlack}
        onMouseLeave={props.mouseLeaveOnBlack}
      >
        <div className="content_container">
          <div className="row m-0">
            <div className="col-12 p-0 fullWidth wow animate__animated animate__slideInDown">
              <img
                src="/images/works/vardhman/2.jpg"
                alt="service1"
                className="w-100"
              />
            </div>
            <div className="col-sm-6 p-0 leftImg wow animate__animated animate__slideInLeft">
              <img
                src="/images/works/vardhman/1.jpg"
                alt="service2"
                className="w-100 h-100"
              />
            </div>
            <div className="col-sm-6 p-0 rightImg wow animate__animated animate__slideInRight">
              <img
                src="/images/works/vardhman/3.jpg"
                alt="service3"
                className="w-100"
              />
              <img
                src="/images/works/vardhman/4.jpg"
                alt="service4"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="ourWorkSlider wow animate__animated animate__slideInLeft">
        <div className="content_container">
          <h2 className="sectionHead colorBlack wow fw-800">
            <img src="/images/icons/line.svg" alt="line" />
            More Works
          </h2>
          <Slider {...settings}>
            <div>
              <div className="sliderInner">
                <a href="/bizdesign" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/bizdesign"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/web/i2m.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Business Design Lab</h2>
                <span className="colorGrey fw-400">Website Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
                <a href="/malik-architecture" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/malik-architecture"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/web/malik.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Malik Architecture</h2>
                <span className="colorGrey fw-400">Website Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
                <a href="/notandas" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/notandas"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/web/notandas.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Notandas</h2>
                <span className="colorGrey fw-400">Website Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
                <a href="/texprocil" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/texprocil"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/web/texprocile.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Texprocil</h2>
                <span className="colorGrey fw-400">Website Design</span>
                </a>
              </div>
            </div>
          </Slider>

          <Link
            to="/works"
            className="unsetAll"
            onClick={() => window.scrollTo(0, 0)}
            onMouseEnter={props.onMouseEnterSmall}
            onMouseLeave={props.onMouseLeaveSmall}
          >
            <span className="commonViewAll">View Our Projects</span>
            <Icon icon="clarity:arrow-line" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default Vardhman;
